import * as React from "react";
import { Event } from "@shapeable/gesda-types";
import { Entity, Space } from "@shapeable/types";
import { flatten } from "lodash";
import { useActivePerson, usePageEntity, useReady } from "@shapeable/ui"
import { entityTypeNameFor } from "@shapeable/utils";

type EntityPermissions = {
  canViewDocuments?: boolean;
  // canViewSpaces?: boolean;
}

type UseEntityPermissionsResult = {
  isLoading: boolean;
  permissions: EntityPermissions;
}

export const useEntityUserPermission = (): UseEntityPermissionsResult => {

  const { person: activePerson, isLoading } = useActivePerson();

  const entity = usePageEntity() as Event;
  const entityType = entityTypeNameFor(entity);

  const isAnticipationWorkshopEvent = entity.slug === 'villars-anticipation-workshop-on-planetarised-humanity';

  if (entityType !== 'Event' && isAnticipationWorkshopEvent) {
    return null;
  };

  const permissions = React.useMemo((): EntityPermissions => {
    let canViewDocuments = false;
    // let canViewSpaces = false;

    if (!!entity?.roles && !!entity?.roles.length) {
      const participants = flatten(entity.roles.map(role => role.person));
      canViewDocuments = participants.some(person => person?.id === activePerson?.id);
    };

    if (activePerson?.canAdmin || canViewDocuments) {
      canViewDocuments = true;
    };

    // if (isLoading && !canViewSpaces) {
    //   canViewSpaces = false;
    // }

    return { canViewDocuments };

  }, [entity, activePerson]);

  return { isLoading, permissions };
}