import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/ui";
import { transformEvent } from "../../utils/transforms";
import { EventExplorerLayout } from "../../components/entities/event-explorer-layout";


export default createGatsbyPageComponent("Event", { layout: EventExplorerLayout, transform: transformEvent });

export const eventQuery = graphql`
  query EventQuery($id: ID!) {
    platform {
      event(id: $id) {
        id slug name path __typename _schema { label pluralLabel }
        startDate
        endDate
        overview { id text }
        description { id text }
        type { id name slug }
        types { id name slug __typename }
        desiredOutcomes { id text }
        keyTakeaways { id text }
        isKeyTakeawaysReady
        documentsDescription { 
          id text 
          linkEmbeds { 
            id name _label url slug __typename
            image {
              id url width height
            }
          } 
        }
        color { id value darkValue }
        openGraph { image { thumbnails { full { url url2x } mainBanner { url url2x } } } }
        images { image { id url url2x width height } }
        files { id name slug __typename
          file { id url formattedSize typeName }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        roles { id name slug 
          role { id name }
          person {
            id name slug path __typename
            organisation { id name slug }
            position
            openGraph { image { thumbnails { bubble { url url2x } } } }
          }
        }
        video {
          id name url
          thumbnail { id url url2x }
        }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height thumbnails { halfBanner { url url2x } }
          }
        }
        location { id name country { id name } }
        connectedPages {
          id name typeLabel slug path __typename type { name slug }
          trend { id name slug path outlineNumber color { value } }
          openGraph { image { url url2x } }
        }
        connectedEvents { 
          id name slug path __typename
          color { id value }
        }
        trends {
          id name slug path outlineNumber __typename color { value }
        }
        trend {
          id name slug path outlineNumber __typename color { value }
        }
        topics {
          id name slug path outlineNumber __typename color { value }
        }
        subTopics {
          id name slug path outlineNumber __typename color { value }
        }
        links { id url name label isDownload page { path } }
        quotes {
          id slug 
          description { id text }
        }
        embeds {
          imageAssets {
            id
            slug
            image {
              id url url2x width height 
            }
          }
        }
        sessions { 
          id name __typename 
          startDate endDate
          icsPath(base: "/downloads")
          description { text }
          room { id name shortName }
          color { id veryLightValue lightValue value darkValue }
          presenters { 
            id name __typename slug path
            bio { html }
            photo { url url2x }
            hasConnectedTopics
            position
            organisation { id name slug }
            openGraph { title description { plain } image { url url2x thumbnails { bubble { url url2x } } } }
          }
          group { id name shortName }
        }
      }
    }
  }
`;